
import {
  defineComponent,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted,
  ref,
  ComponentPublicInstance,
} from 'vue';
import { sizes, props, components, emits } from '@/mixins/view';

export default defineComponent({
  components,
  props,
  emits,
  setup(props, { emit }) {
    const root = ref<ComponentPublicInstance | null>(null);
    const tierOne = [...Array(10).keys()].map((i) => ({
      src: {
        jpg: `lookbook/jpg/basedt01-lb${i + 1}.jpg`,
        webp: `lookbook/webp/basedt01-lb${i + 1}.webp`,
      },
      alt: `Lookbook Tier 1 Image ${i + 1}`,
      class: 'tierOne',
      awake: false,
    }));
    const tierTwo = [...Array(11).keys()].map((i) => ({
      src: {
        jpg: `lookbook/jpg/basedt02-lb${i + 1}.jpg`,
        webp: `lookbook/webp/basedt02-lb${i + 1}.webp`,
      },
      class: 'tierTwo',
      alt: `Lookbook Tier 2 Image ${i + 1}`,
      awake: false,
    }));

    const images = ref([...tierTwo, ...tierOne]);

    const onDismiss = () => emit('dismiss');

    let itemRefs: Array<Element> = [];
    const setItemRef = (el: Element) => {
      itemRefs.push(el);
    };
    let observer: IntersectionObserver | null = null;
    onMounted(() => {
      if (root.value) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                observer!.unobserve(entry.target);
                const index = itemRefs.findIndex((ref) => ref === entry.target);
                images.value[index].awake = true;
              }
            });
          },
          {
            root: root.value.$el,
          }
        );
        itemRefs.forEach((el) => {
          observer!.observe(el);
        });
      }
    });
    onBeforeUpdate(() => {
      itemRefs.forEach((item) => {
        observer!.unobserve(item);
      });
      itemRefs = [];
    });
    onBeforeUnmount(() => {
      observer!.disconnect();
    });

    return {
      root,
      images,
      onDismiss,
      setItemRef,
      sizes: sizes([0.4, 0.8]),
    };
  },
});
